@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-spin-fullscreen {
  z-index: 9999;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  /* Show scrollbar */
  .scrollbar::-webkit-scrollbar {
    display: block;
  }
  .scrollbar {
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;  /* Firefox */
  }
}

th.diagonal {
  position: relative;
  width: 150px;
  height: 100px;
  text-align: center;
}

.diagonal::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
  /* Hücreyi ikiye bölen çizgi */
  border-top: 1px solid #d1d5dc;
  border-right: 0px solid #000;
  transform: rotate(33.5deg);
  transform-origin: top left;
}
/* Sağ üst köşeye yerleştirilen metin */
.text-top {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 14px;
}
/* Sağ alt köşeye yerleştirilen metin */
.text-bottom {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 14px;
  text-align: left;
}